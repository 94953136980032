<template>
  <v-dialog
    v-model="dialog"
    fullscreen scrollable
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        text small
        color="color3"
        :disabled="disabled"
      >
        <v-icon small class="mr-2">fas fa-whistle</v-icon> Keep Score
      </v-btn>
    </template>
    <live-scoring
      @close-click="endLive"
      :division="division"
      :round="round"
      :orientation="orientation"
      :matchIn="matchIn"
      :active="dialog"
    ></live-scoring>
  </v-dialog>
</template>

<script>
import LiveScoring from '@/components/Tournament/LiveScoring/LiveScoring.vue'
import RouteMixin from '@/Mixins/RouteMixin'
import { mapGetters } from 'vuex'
import * as mutations from '@/store/MutationTypes'

export default {
  mixins: [RouteMixin],
  props: ['matchIn', 'disabled', 'round', 'division'],
  data () {
    return {
      dialog: false,
      orientation: null
    }
  },
  computed: {
    ...mapGetters(['isLiveScoring', 'matchProps'])
  },
  methods: {
    endLive () {
      this.dialog = false
      this.matchProps && this.matchProps.key && this.$VBL.liveScoring.end({ match: this.matchIn.dto, key: this.matchProps.key })
      this.$store.commit(mutations.SET_MATCH_PROPS, null)
      this.$emit('ended')
    },
    setOrientation () {
      this.orientation = window.innerHeight > window.innerWidth ? 'portrait' : 'landscape'
    }
  },
  mounted () {
    this.setOrientation()
    window.addEventListener('resize', this.setOrientation)
    if (this.isLiveScoring && this.matchIn.id === this.matchProps.id) {
      this.dialog = true
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setOrientation)
  },
  components: {
    LiveScoring
  }
}
</script>

<style scoped>

</style>
