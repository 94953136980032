import { render, staticRenderFns } from "./TeamRow.vue?vue&type=template&id=f041cdba&scoped=true&"
import script from "./TeamRow.vue?vue&type=script&lang=js&"
export * from "./TeamRow.vue?vue&type=script&lang=js&"
import style0 from "./TeamRow.vue?vue&type=style&index=0&id=f041cdba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f041cdba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAvatar,VCheckbox,VImg,VTextField})
