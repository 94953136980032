export default function (dto, og, required, exclude) {
  const result = {}
  const r = required || []
  const x = exclude || []
  for (var prop in dto) {
    if (x.includes(prop)) continue
    if (r.includes(prop)) {
      result[prop] = dto[prop]
      continue
    }
    // eslint-disable-next-line no-prototype-builtins
    if (og.hasOwnProperty(prop)) {
      const a = og[prop]
      const b = dto[prop]
      if (a !== b) {
        result[prop] = dto[prop]
      }
    } else {
      result[prop] = dto[prop]
    }
  }
  return result
}
