<template>
  <tr>
    <td class="pa-0" :class="{'border-right': games.length, 'active': active}">
      <table>
        <tr>
          <td class='pa-1 d-flex align-center justify-center' :class="{winner: item.winner}" :width="item.logo ? hasSlots ? '64px' : '64px' : '48px'" style="height: 100% !important;">
            <template v-if="hasSlots && !item.logo">
              <v-avatar
                v-for="slot in item.slots"
                :key="slot"
                size="24"
                :color="teamColorsInverse[slot - 1]"
              >
                <span class="white--text">{{slot}}</span>
              </v-avatar>
            </template>
            <div v-else-if="debug">{{seeds}}</div>
            <div v-else-if="!hideSeeds && !item.logo">{{item.seed}}</div>
            <v-img
              v-if="item.logo"
              :src="item.logo"
              height="36"
              max-width="48"
              contain
            ></v-img>
          </td>
          <td
            class='pa-0'
            :class="{winner: item.winner, 'subtitle-1': item.winner && !xs, 'clickable': clickable, 'color3--text': clickable}"
            @click="teamClick"
          >
            <div v-for="(name, i) in names" :key="`name_${i}`">
              <country-flag
                v-if="item.countryCode"
                :country-code="item.countryCode"
              />
              {{name}}
              <div class="grey--text" v-if="displayAsDual && dualDisplay">{{ dualDisplay }}</div>
              <div class="grey--text xsmall" v-if="vw">{{ item.map }}: {{ teamIdString }}</div>
            </div>
          </td>
        </tr>
      </table>
    </td>
    <!-- GAMES - ALL DEVICES -->
    <td
      width="40px"
      max-width="40px"
      v-for="(game, i) in games"
      :key="'game_' + i"
      class="text-center subtitle-1 px-1"
      :class="{'border-right': i < games.length - 1, winner: game.winner === item.map}"
    >
      <span v-if="!editing">{{ !game._winner ? game[item.map] : game._winner === item.map ? 'W' : 'L'}}</span>
      <template v-else>
        <v-text-field
          ref="home"
          class="pa-0"
          v-if="!winLoss"
          v-model="games[i][item.map]"
          type="number"
          min="0"
          max="99"
          single-line
          hide-details
          color="color3"
          background-color="grey lighten-3"
          :disabled="i >= match.completeIn"
          :tabindex="games.length > 1 ? `${match.number}${1 + (i * 2)}` : 0"
        ></v-text-field>
        <v-checkbox
          v-else
          hide-details
          v-model="games[i]._winner"
          :value="item.map || 'NA'"
          :disabled="i >= match.completeIn || !iTeams.length"
          color="color3"
        ></v-checkbox>
      </template>
    </td>
  </tr>
</template>

<script>
import flatten from '@/helpers/ArrayFlatten'
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'
import { formatArray } from '@/Filters'
const CountryFlag = () => import('@/components/Utils/CountryFlag.vue')

export default {
  components: {
    CountryFlag
  },
  props: [
    'item',
    'match',
    'games',
    'editing',
    'winLoss',
    'division',
    'teamColorsInverse',
    'bracketCard',
    'hideSeeds',
    'active',
    'lineupLock',
    'vw'
  ],
  data () {
    return {
      debug: false
    }
  },
  computed: {
    ...mapGetters(['tournament', 'user']),
    iTeams () {
      return this.item.teams && this.item.teams.filter(f => !!f)
    },
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    teamWidth () {
      return (40 * this.games.length) + (this.item.logo ? 96 : 48)
    },
    seed () {
      return this.item.seed
    },
    displayAsDual () {
      return this.match.isDual || this.division.is('dualDisplay')
    },
    hasSlots () {
      return this.item.slots && this.item.slots.filter(f => f).length > 0 && !this.tournament.isLeague
    },
    useTeamNames () {
      return this.division.useTeamNames
    },
    names () {
      if (this.item.holder) return [this.item.holder]
      if (this.displayAsDual) return [this.teamNames.join(' / ')]
      if (this.lineupPlayers) return this.lineupPlayers
      if (this.playerNames.length > this.division.numOfPlayers) return [this.teamNames.join(' / ')]

      if (this.useLogos && !this.match.isDual && this.division.numOfPlayers <= 2) {
        return this.playerNames
      }
      if (this.xs && !this.match.isDual) {
        if (this.division.numOfPlayers <= 2 && !this.division.useTeamNames) {
          return this.playerNames
        }
        return this.iTeams.map(t => t.name)
      }
      // Not XS
      return [this.teamNames.join(' / ')]
    },
    seeds () {
      return this.iTeams.map(t => t.seed)
    },
    playerNames () {
      return this.iTeams && flatten(this.iTeams.map(t => t.playerNames))
    },
    teamNames () {
      return this.iTeams && this.iTeams.map(m => m.name)
    },
    teamIds () {
      return this.iTeams && this.iTeams.map(m => m.id)
    },
    teamIdString () {
      return this.teamIds && formatArray(this.teamIds)
    },
    teamPlayers () {
      return this.iTeams && flatten(this.iTeams.map(m => m.players))
    },
    clickable () {
      return this.iTeams && this.iTeams.length === 1
    },
    useLogos () {
      return this.division && this.division.useLogos
    },
    pairN () {
      if (!this.match.isDual) return 1
      return (this.match.dualMatchN || 0) + this.division.dualAdj
    },
    dualDisplay () {
      if (this.teamPlayers && this.teamPlayers.length === 2) {
        const p = this.teamPlayers
        p && p.sort(firstBy('lastName').thenBy('firstName'))
        return p.map(m => `${this.$vuetify.breakpoint.xs ? m.lastName : m.name}${m.jersey ? ' #' + m.jersey : ''}`).join('/')
      }
      if (this.lineupPlayers && this.displayLineups) return this.lineupPlayers.join('/')
      const x = this.lineupPlayers ? ' - Lineup Submitted' : ''
      if (!this.match.isDual) return false
      return `Pair ${this.pairN} ${x}`
    },
    displayLineups () {
      const t = this.teamIds.filter(f => this.lineupLock.userTeams.includes(f))
      return this.lineupLock.canDisplay || t.length > 0 || (this.lineupLock.view.adminAndRefs && this.lineupLock.userTeams.length === 0)
    },
    lineups () {
      return this.match && this.match.lineups && this.match.lineups.filter(f => this.teamIds.includes(f.teamId))
    },
    myLineups () {
      return this.lineups && flatten(this.lineups.map(m => m.positions.filter(f => f.n === this.pairN && f.type === 'dual')))
    },
    lineupPlayers () {
      if (!this.myLineups || this.myLineups.length === 0) return null
      const l = flatten(this.myLineups.map(m => m.players))
      const p = l && this.teamPlayers && this.teamPlayers.filter(f => l.includes(f.id))
      if (p.length === 0) return null
      p && p.sort(firstBy('lastName').thenBy('firstName'))
      return p && p.map(m => `${this.$vuetify.breakpoint.xs ? m.lastName : m.name}${m.jersey ? ' #' + m.jersey : ''}`)
    }
  },
  methods: {
    teamClick () {
      if (this.clickable) {
        this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: this.iTeams && this.iTeams[0].id } })
      }
    },
    focus () {
      if (this.$refs.home) {
        this.$refs.home[0].focus()
      }
    }
  }
}
</script>

<style scoped>
.active {
  border-bottom: 0 !important;
}
.subtitle-1 {
  line-height: 1rem !important
}
</style>
